<template>
  <div :class="$style.container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppLayoutColumnsContent'
}
</script>

<style lang="scss" module>
.container {
  margin: 0 -3.3em 0 -4em;
  @include down(md) {
    display: block;
    width: calc(100vw - 9em);
    //  margin-left: -4em;
    //  margin-right: -4em;
  }
  @include down(sm) {
    display: block;
    margin-left: -1.6em;
    margin-right: -1.6em;
    width: 100vw;
  }
}
</style>
