import IconCode from '@/assets/images/task/type/code.svg?url'
import IconCs from '@/assets/images/task/type/cs.svg?url'
import IconQuiz from '@/assets/images/task/type/quiz.svg?url'
import IconSteam from '@/assets/images/task/type/steam.svg?url'
import IconDota2 from '@/assets/images/task/type/dota2.png'
import IconVk from '@/assets/images/task/type/vk.svg?url'
import IconYoutube from '@/assets/images/task/type/youtube.svg?url'
import IconScope from '@/assets/images/task/type/scopegg.svg?url'
import IconCsmoney from '@/assets/images/task/type/csmoney.svg?url'

export const taskTypeIcons = {
  code: IconCode,
  cs: IconCs,
  quiz: IconQuiz,
  steam: IconSteam,
  dota2: IconDota2,
  vk: IconVk,
  youtube: IconYoutube,
  scopegg: IconScope,
  csmoney: IconCsmoney
}
