<template>
  <div :class="[$style.wrap, isAvailable && $style.available]">
    <div v-if="quiz" :class="$style.container">
      <div :class="$style.content">
        <div :class="$style.title">
          <span :class="[$style.text, $style.accent]">
            Участвуй в викторине -
          </span>
          <span :class="$style.text">получай награду</span>
        </div>
        <div :class="$style.next" v-if="!isAvailable">
          <span :class="$style.text">До следующей викторины</span>
          <div :class="$style.timer">
            <ui-time
              :class="$style.text"
              :value="activationAt"
              clock
              :timeout="1000"
              to-ms
              format="future-time"
              @timeout="checkAvailable"
            />
          </div>
        </div>
        <div :class="$style.action" v-else>
          <div :class="$style.actionContent">
            <ui-coin
              :class-value="$style.coinValue"
              :class-icon="$style.coin"
              :value="15000"
              size="custom"
            />
            <div :class="$style.text">Каждую неделю</div>
          </div>
          <a
            :class="$style.btn"
            @click="onOpenQuiz"
            v-analytics.click="{
              gtm: ['paripass', 'quizzes', 'quiz_click'],
              amplitude: [
                'banner_click',
                {
                  banner_name: 'quiz'
                }
              ]
            }"
          >
            <span :class="$style.btnText">Начать</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Get": "Викторина",
    "skin of the day!": "дня!"
  }
}
</i18n>

<script>
import UiCoin from '~/components/ui/coin/index.vue'
import UiTime from '~/components/ui/time'

export default {
  name: 'QuizBanner',
  components: {
    UiCoin,
    UiTime
  },
  data() {
    return {
      isAvailable: false
    }
  },
  props: {
    quiz: {
      type: Object,
      default: null
    }
  },
  computed: {
    activationAt() {
      return this.quiz?.activation_at || Date.now() / 1000
    }
  },
  methods: {
    checkAvailable() {
      const activationAt = this.quiz?.activation_at || 0
      const now = Date.now() / 1000
      this.isAvailable =
        this.quiz && (!activationAt || (activationAt && activationAt <= now))
    },
    onOpenQuiz() {
      this.$emit('open-quiz', this.quiz.id)
    }
  },
  watch: {
    quiz: {
      handler() {
        this.checkAvailable()
      },
      immediate: true
    }
  },
  emits: ['open-quiz']
}
</script>

<style lang="scss" module>
.wrap {
  width: 100%;
}

.container {
  border-radius: 0.6em;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(@/components/quiz/banner/assets/bg.jpg);
  background-position: center;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 4em 0 2.6em;

  @include hiDPI {
    background-image: url(@/components/quiz/banner/assets/bg@2x.jpg);
  }

  @include down(sm) {
    padding: 1em 2em 0.5em 1.5em;
    background-image: url(@/components/quiz/banner/assets/bg-sm.jpg);

    @include hiDPI {
      background-image: url(@/components/quiz/banner/assets/bg-sm@2x.jpg);
    }
  }

  .available & {
    @include down(sm) {
      background-image: url(@/components/quiz/banner/assets/bg-sm.jpg);

      @include hiDPI {
        background-image: url(@/components/quiz/banner/assets/bg-sm@2x.jpg);
      }
    }
  }
}
.content {
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include down(md) {
    height: 6.5em;
  }
  @include down(sm) {
    display: block;
    height: 13em;
  }
}
.next {
  display: flex;
  align-items: center;
  letter-spacing: -0.2em;
  @include down(sm) {
    letter-spacing: -0.02em;
    margin-top: 5.6em;
  }
  .text {
    font-size: 2.2em;
    line-height: 109.5%;
    text-align: right;
    @include down(md) {
      letter-spacing: -0.01em;
      font-size: 1.3em;
    }
    @include down(sm) {
      font-size: 1em;
      max-width: 9.6em;
      text-align: left;
    }
  }
}
.title {
  letter-spacing: -0.2em;
  @include down(sm) {
    letter-spacing: -0.17em;
  }
  .text {
    font-size: 3em;
    line-height: 109.5%;
    display: block;
    @include down(md) {
      font-size: 2.2em;
    }
    @include down(sm) {
      font-size: 1.8em;
      line-height: 109.5%;
    }
  }
  .accent {
    color: #bdee00;
  }
}
.timer {
  color: #fff;
  padding: 0.4em 0.7em;
  position: relative;
  margin-left: 2em;

  @include down(sm) {
    margin-left: 0;
    padding: 0.3em 0.7em;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background: #00c7b1;
    border-radius: 0.2em;
    transform: skew(-15deg);
  }
  .text {
    position: relative;
    z-index: 1;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1.8em;
    line-height: 100%;
    letter-spacing: -0.02em;
    @include down(md) {
      font-size: 1.3em;
    }
    @include down(sm) {
      font-size: 1.4em;
    }
  }
}
.action {
  display: flex;
  align-items: center;
  @include down(sm) {
    margin-top: 4.4em;
  }
  .text {
    font-size: 2.1em;
    font-family: var(--primary-font);
    font-style: italic;
    font-weight: bold;
    @include down(md) {
      font-size: 1.6em;
    }
    @include down(sm) {
      font-size: 1.2em;
    }
  }
}
.coin {
  width: 3em;
  height: 3em;
  margin-right: 0.3em;
  @include down(md) {
    width: 2em;
    height: 2em;
  }
  @include down(sm) {
    width: 2.6em;
    height: 2.6em;
    margin-left: -0.3em;
  }
}
.coinValue {
  font-size: 2.1em;
  font-family: var(--primary-font);
  font-style: italic;
  font-weight: bold;
  @include down(md) {
    font-size: 1.6em;
  }
  @include down(sm) {
    font-size: 2em;
  }
}
.actionContent {
  @include down(sm) {
    flex: 1 1;
  }
}
.btn {
  margin-left: 6.2em;
  position: relative;
  height: 3.7em;
  width: 12.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include down(md) {
    margin-left: 3em;
    height: 3.2em;
    width: 10.6em;
  }
  @include down(sm) {
    margin-left: 0;
  }
  &:hover:before {
  }
  &:before {
    transition: background 0.2s;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    background: linear-gradient(288.61deg, #bdee00 2.73%, #00c7b1 62.51%);
    border-radius: 0.4em;
    transform: skew(-18deg);
  }
  .btnText {
    position: relative;
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.8em;
    color: black;
    @include down(md) {
      font-size: 1.5em;
    }
  }
}
</style>
