<template>
  <div :class="$style.container" v-if="showWarning">
    <svg
      :class="$style.icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 11.2498V16.2498M14.9998 21.2498H15.0123M12.7998 4.94607L2.27228 22.5211C2.05537 22.897 1.94063 23.3231 1.93946 23.757C1.93829 24.191 2.05073 24.6177 2.26561 24.9948C2.48048 25.3718 2.79031 25.686 3.16428 25.9062C3.53825 26.1264 3.96334 26.2449 4.39728 26.2498H25.4535C25.8875 26.2449 26.3126 26.1264 26.6865 25.9062C27.0605 25.686 27.3703 25.3718 27.5852 24.9948C27.8001 24.6177 27.9125 24.191 27.9113 23.757C27.9102 23.3231 27.7954 22.897 27.5785 22.5211L17.0498 4.94607C16.8281 4.58106 16.5161 4.27931 16.1439 4.0699C15.7717 3.86049 15.3518 3.75049 14.9248 3.75049C14.4977 3.75049 14.0779 3.86049 13.7057 4.0699C13.3335 4.27931 13.0215 4.58106 12.7998 4.94607Z"
        stroke="#E73A6B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <p :class="$style.text">
      <span>{{ warningText }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from '~/stores/common/settings'

const settingsStore = useSettingsStore()

const showWarning = computed(
  () => settingsStore.data?.project?.settings.warning_active
)
const warningText = computed(
  () => settingsStore.data?.project?.settings.warning_message
)
</script>

<style module lang="scss">
.container {
  padding: 1.4em 2em;
  border: 1px solid #e73a6b;
  background: linear-gradient(
      0deg,
      rgba(21, 22, 23, 0.48),
      rgba(21, 22, 23, 0.48)
    ),
    linear-gradient(
      93.34deg,
      rgba(231, 58, 107, 0.42) 1.41%,
      rgba(231, 58, 107, 0) 24.86%
    );
  margin: 1.8em 3.3em 1.8em 4em;
  display: flex;
  align-items: center;
  border-radius: 0.4em;

  @include down(md) {
    margin-right: 4em;
  }

  @include down(sm) {
    margin-right: 1.6em;
    margin-left: 1.6em;
  }
}

.icon {
  width: 3em;
  height: auto;
  display: block;
  margin-right: 2em;
  flex-shrink: 0;
}

.text {
  span {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: #fff;
  }
}
</style>
