<template>
  <div :class="$style.bannerWrap">
    <ui-spinner v-if="quizLoading" :class="$style.bannerSpinner" />
    <quiz-banner
      v-else-if="nextQuiz"
      :quiz="nextQuiz"
      @open-quiz="onOpenQuiz"
    />

    <pass-promo v-else />
    <!-- <survey-promo v-else /> -->
    <!-- <pixel-promo v-else /> -->
    <!-- <pari-promo v-else /> -->
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import PassPromo from '@/components/banners/pass-promo/index.vue'
// import SurveyPromo from '@/components/banners/survey-promo/index.vue'
// import PixelPromo from '@/components/banners/pb-promo/index.vue'
// import PariPromo from '@/components/banners/pari-promo/index.vue'
import QuizBanner from '@/components/quiz/banner/index.vue'
import UiSpinner from '@/components/ui/spinner/index.vue'
import { useQuizzesItemsStore } from '~/stores/quizzes/items'
import { useQuizzesItemStore } from '~/stores/quizzes/item'
import { useAccountStore } from '@/stores/account'

const accountStore = useAccountStore()
const quizzesItemsStore = useQuizzesItemsStore()
const quizzesItemStore = useQuizzesItemStore()
const { $dialog } = useNuxtApp()

const quizLoading = ref(true)
const nextQuiz = computed(() => quizzesItemsStore.nextQuiz)
const isAuthorized = computed(() => accountStore.isAuthorized)

const getQuiz = (...args) => quizzesItemsStore.fetchItems(...args)

const onOpenQuiz = id =>
  $dialog({
    content: defineAsyncComponent(
      () => import('~/components/task/quiz/index.vue')
    ),

    contentProps: {
      id,
      preload: false,
      showReward: true
    },

    fetchData: () => quizzesItemStore.loadAndStartQuiz({ id })
  })

const init = async () => {
  try {
    quizLoading.value = true
    if (isAuthorized.value) {
      await getQuiz({ 'filter[tag]': 'pari' })
    }
  } finally {
    quizLoading.value = false
  }
}

onMounted(async () => {
  await init()
})

watch(isAuthorized, (value, oldValue) => {
  if (oldValue === false) {
    init()
  }
})
</script>

<style lang="scss" module>
.bannerWrap {
  position: relative;
  min-height: 8em;
  display: flex;
  margin-bottom: 3em;

  .bannerSpinner {
    margin: auto;
  }

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 1em;
    z-index: -1;
  }

  @include down(md) {
    min-height: 6.5em;
    margin-bottom: 2em;
  }
  @include down(sm) {
    min-height: 13em;
    margin-bottom: 3em;
  }
}
</style>
