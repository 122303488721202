<template>
  <ui-link :class="$style.banner" to="/battlepass" draggable="false">
    <h3 :class="$style.heading">
      <span :class="$style.t">
        {{ t('takeRewards') }} <br :class="[$style.break, $style.pre]" />
        {{ t('in') }} <br :class="[$style.break, $style.post]" />
        <span :class="$style.accent">{{ t('seasonPass') }}</span>
      </span>
    </h3>

    <img
      :class="$style.rewards"
      :srcset="`${Rewards2xSrc} 2x, ${RewardsSrc} 1x`"
      :src="RewardsSrc"
      alt=""
    />

    <img
      :class="$style.level"
      :srcset="`${Level2xSrc} 2x, ${LevelSrc} 1x`"
      :src="LevelSrc"
      alt=""
    />

    <img
      :class="$style.bestReward"
      :srcset="`${BestReward2xSrc} 2x, ${BestRewardSrc} 1x`"
      :src="BestRewardSrc"
      alt=""
    />
  </ui-link>
</template>

<i18n>
{
  "ru": {
    "takeRewards": "Забирай награды",
    "in": "в",
    "seasonPass": "SEASON PASS"
  }
}
</i18n>

<script lang="ts" setup>
import UiLink from '~/components/ui/link/index.vue'
import Rewards2xSrc from './assets/rewards@2x.png'
import RewardsSrc from './assets/rewards.png'
import Level2xSrc from './assets/level@2x.png'
import LevelSrc from './assets/level.png'
import BestReward2xSrc from './assets/best-reward@2x.png'
import BestRewardSrc from './assets/best-reward.png'

defineOptions({
  name: 'PassPromoBanner'
})

const { t } = useI18n()
</script>

<style lang="scss" module>
.banner {
  border-radius: 0.6em;
  padding: 0.2em 3em 0.2em 1.8em;
  flex: 1;
  height: 8em;
  background: url(./assets/bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include hiDPI {
    background-image: url(./assets/bg@2x.jpg);
  }

  @include down(md) {
    font-size: 0.66em;
    height: 9.85em;
  }

  @include down(sm) {
    font-size: 1em;
    padding: 0;
    height: 14.5em;
    background-image: url(./assets/bg-sm.jpg);
    align-items: flex-start;

    @include hiDPI {
      background-image: url(./assets/bg-sm@2x.jpg);
    }
  }
}

.heading {
  font-family: var(--primary-font);
  font-weight: 700;
  text-transform: uppercase;

  @include down(sm) {
    margin: 1.2em 0 0 1.5em;
  }

  .t {
    font-size: 2.3em;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: var(--app-color-text);

    @include down(sm) {
      font-size: 1.8em;
    }
  }

  .break {
    &.pre {
      display: none;

      @include down(sm) {
        display: inline;
      }
    }

    &.post {
      @include down(sm) {
        display: none;
      }
    }
  }
}

.accent {
  color: rgba(189, 238, 0, 1);
}

.rewards {
  width: 98.8em;

  @include down(sm) {
    display: none;
  }
}

.bestReward {
  display: none;

  @include down(sm) {
    position: relative;
    right: 0.4em;
    top: 0.6em;
    display: block;
    width: 10.3em;
  }
}

.level {
  display: none;

  @include down(sm) {
    display: block;
    position: absolute;
    bottom: 1.5em;
    left: 1.5em;
    width: 17.5em;
  }
}
</style>
