import type { Quizzes } from 'lvl-io-sdk'
import { createItemStore } from '~/utils/pinia/light-item-store'
import { useQuizzesItemsStore } from '~/stores/quizzes/items'

type Quiz = Quizzes.Quiz &
  Pick<Quizzes.QuizExpanded, 'coin'> & {
    status?: Quizzes.StatusExpanded | null
  }

export const useQuizzesItemStore = createItemStore(
  'quizzes-item',
  {
    getItemRequest: async () => {
      const { $sdk } = useNuxtApp()
      const { getQuiz } = await $sdk.module('quizzes')

      return (
        ...[id, requestQuery, pathOptions, requestOptions]: Parameters<
          typeof getQuiz<Quiz>
        >
      ) =>
        getQuiz<Quiz>(
          id,
          {
            expand: ['status.questions.answers', 'coin'],
            ...(requestQuery ?? {})
          },
          pathOptions,
          requestOptions
        )
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    const loadAndStartQuiz = async ({ id }: { id: Quiz['id'] }) => {
      const quiz = await context.fetchItem(id)

      if (!quiz.status) {
        await $sdk.module('quizzes').then(({ startQuiz }) => startQuiz(id))

        const quizRefetched = await context.fetchItem(id)

        return quizRefetched
      }

      return quiz
    }

    const sendAnswers = async ({
      quizId,
      answers
    }: {
      quizId: Quiz['id']
      answers: Quizzes.ResultQuizBody
    }) => {
      const data = await $sdk
        .module('quizzes')
        .then(({ resultQuiz }) => resultQuiz(quizId, answers))

      const quiz = context.data.value

      const updates = {
        id: quizId,
        status: {
          completed_at: data.completed_at,
          correct_answers: data.correct_answers,
          questions: quiz!.status!.questions,
          reward: data.reward,
          start_at: data.start_at
        }
      }

      context.updateData(updates)

      const quizzesItemsStore = useQuizzesItemsStore()
      quizzesItemsStore.updateItem(updates.id, updates)
    }

    return { loadAndStartQuiz, sendAnswers }
  }
)
