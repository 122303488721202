import { createItemsStore } from '~/utils/pinia/light-items-store'
import type { Tasks } from 'lvl-io-sdk'

type ExternalReward = Tasks.Task['rewards'][number]

type Resource = Pick<
  ExternalReward['resource'],
  'id' | 'name' | 'image_url' | 'source' | 'source_id'
>

type Reward = Pick<ExternalReward, 'amount'> & {
  resource: Resource
}

type Task = Pick<
  Tasks.Task,
  'id' | 'type' | 'content' | 'options' | 'stat' | 'memberStatus'
> & {
  rewards: Reward[]
}

export const useTasksListStore = createItemsStore(
  'tasks-list',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        tasks: { idKey, getItems, getMoreItems }
      } = await $sdk.module('tasks')

      return {
        idKey,

        getItems: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getItems<Task>
          >
        ) =>
          getItems<Task>(
            {
              sort: 'completed_at,-priority,-id',
              fields: [
                'id',
                'type',
                'content',
                'options',
                'stat',
                'memberStatus',
                'rewards.amount',
                'rewards.resource.id',
                'rewards.resource.name',
                'rewards.resource.image_url',
                'rewards.resource.source',
                'rewards.resource.source.id',
                'rewards.resource.source_id'
              ],
              perPage: 14,
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          ),

        getMoreItems: getMoreItems<Task>
      }
    }
  },
  () => {
    const defaults = reactive({
      status: {
        default: '',
        available: {
          'filter[unlocked_at]': 'NULL'
        },
        inprogress: {
          'filter[not][unlocked_at]': 'NULL',
          'filter[completed_at]': 'NULL'
        },
        completed: {
          'filter[progress]': 100
        }
      }
    })

    return { defaults }
  }
)
