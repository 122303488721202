<template>
  <div :class="$style.container">
    <ui-time format="future-time" :value="value" to-ms clock :timeout="1000" />
  </div>
</template>

<script>
import UiTime from '~/components/ui/time'

export default {
  name: 'TaskCommonTimer',
  components: {
    UiTime
  },
  props: {
    value: {
      type: [Number, String],
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  text-align: center;
  color: #fff;
  padding: 0 1.4em;
  box-shadow: 0 2px 16px rgba(19, 4, 37, 0.25);
  time {
    position: relative;
    z-index: 1;
    font-family: var(--primary-font), sans-serif;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 186%;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background: #753bbd;
    border-radius: 0.2em;
    transform: skew(-12deg);
  }
}
</style>
