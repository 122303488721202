<template>
  <div :class="$style.taskAuth">
    <img src="./assets/bg.svg?url" alt="" />

    <div :class="$style.content">
      <div :class="$style.images">
        <img src="./assets/lg.jpg" alt="" />
        <img src="./assets/md.jpg" alt="" />
        <div :class="$style.arrows">
          <svg
            v-for="i in 5"
            :key="i"
            width="77"
            height="77"
            viewBox="0 0 77 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.6476 7.30226L21.144 12.4446C9.70595 16.2384 3.50912 28.5863 7.30296 40.0244L12.4453 55.528C16.2391 66.966 28.587 73.1629 40.0251 69.369L55.5287 64.2267C66.9667 60.4328 73.1635 48.0849 69.3697 36.6469L64.2274 21.1433C60.4335 9.70527 48.0856 3.50841 36.6476 7.30226Z"
              fill="#753BBD"
            />
            <path
              d="M51.3623 26.6743L30.9732 26.6803L29.1183 32.3927H43.3765L25.0169 44.7749C23.6107 45.7235 23.3367 47.6808 24.4267 48.9786C25.3662 50.0988 27.0014 50.3277 28.2119 49.5116L46.7401 37.0149L42.6719 49.5327H49.1792L54.9849 31.6609C55.7858 29.2008 53.949 26.6743 51.3623 26.6743V26.6743Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      <div :class="$style.info">
        <h1 :class="$style.title">
          <span>{{ t('title') }}</span>
        </h1>
        <p :class="$style.desc">
          <span>{{ t('desc') }}</span>
        </p>
        <ui-button
          uppercase
          :class="$style.btn"
          theme="accent"
          fill
          size="large"
          @click="onLogin"
          :loading="fetching"
          v-analytics.click.gtm="[
            'landing',
            'registration',
            'registration_link_button_click'
          ]"
        >
          {{ t('btn') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "title": "Выполняй задания — получай призы!",
    "desc": "Войди и открой доступ к новым заданиям",
    "btn": "Войти"
  },
  "en": {
    "title": "Complete tasks — get prizes!",
    "desc": "Log in and open access to new tasks",
    "btn": "Enter"
  }
}
</i18n>

<script setup>
import UiButton from '@/components/ui/button'
import UiLink from '~/components/ui/link/index.vue'
const { t } = useI18n()
const { $sdk } = useNuxtApp()
const fetching = ref(true)

onMounted(async () => {
  await $sdk.module('auth').then(({ getAccount }) => getAccount())
  fetching.value = false
})

const onLogin = async () => {
  fetching.value = true
  try {
    await $sdk.module('auth').then(({ login }) => login())
  } catch (e) {
  } finally {
    fetching.value = false
  }
}
</script>

<style lang="scss" module>
.taskAuth {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  @include down(sm) {
    font-size: 0.6em;
    padding: 10em 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
    background: linear-gradient(180deg, #0a8ec4 0%, rgba(76, 23, 226, 0) 100%);
  }

  > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  > .content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > .images {
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 12em;

      > img {
        height: auto;
        display: block;
        border-radius: 0.8em;

        &:nth-child(1) {
          width: 23.2em;
          position: relative;
          left: -8.5em;
          transform: rotate(-15.085deg);
          box-shadow: 0 4em 3em 0 rgba(5, 12, 25, 0.35);
        }

        &:nth-child(2) {
          width: 19.4em;
          top: 8.5em;
          position: absolute;
          right: -9.5em;
          transform: rotate(20.243deg);
          z-index: 2;
          box-shadow: 0 3.7em 2.8em 0 rgba(5, 12, 25, 0.35);
        }
      }

      > .arrows {
        > svg {
          position: absolute;
          display: block;
          height: auto;

          &:nth-child(1) {
            width: 2.8em;
            right: calc(100% + 18em);
            top: 16em;
            transform: rotate(15.374deg);
          }
          &:nth-child(2) {
            width: 4.7em;
            right: calc(100% + 10em);
            top: 24em;
            transform: rotate(2.306deg);
          }
          &:nth-child(3) {
            width: 7.5em;
            left: calc(100% + 13em);
            top: 6em;
            transform: rotate(24.893deg);
          }
          &:nth-child(4) {
            width: 3.8em;
            left: calc(100% + 11em);
            top: 20em;
            transform: rotate(9.915deg);
          }
          &:nth-child(5) {
            width: 2.8em;
            left: calc(100% + 18em);
            top: 26em;
            transform: rotate(5.846deg);
          }
        }
      }
    }

    > .info {
      text-align: center;
      padding: 3.2em;

      > .title {
        font-family: var(--primary-font);
        margin-bottom: 0.8em;

        span {
          font-size: 2.4em;
          font-style: normal;
          font-weight: 700;
          line-height: 1.4;
          text-transform: uppercase;
        }
      }

      > .desc {
        span {
          color: #acb0d5;
          text-align: center;
          font-size: 1.6em;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
        }
      }

      > .btn {
        margin-top: 4em;
        width: 30em;

        > span {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
