import type { Quizzes } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

type Quiz = Quizzes.Quiz & Pick<Quizzes.QuizExpanded, 'status' | 'coin'>

export const useQuizzesItemsStore = createItemsStore(
  'quizzes-items',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        quizzes: { idKey, getItems, getMoreItems }
      } = await $sdk.module('quizzes')

      return {
        idKey,

        getItems: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getItems<Quiz>
          >
        ) =>
          getItems<Quiz>(
            {
              expand: ['status', 'coin'],
              sort: 'activation_at',
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          ),

        getMoreItems: getMoreItems<Quiz>
      }
    }
  },
  context => {
    const available = computed(() => {
      const now = Date.now() / 1000

      return context.items.value.filter(
        item => !item.deactivation_at || (item.deactivation_at || 0) > now
      )
    })

    const nextQuiz = computed(() =>
      available.value.find(item => !item.status?.completed_at)
    )

    return { available, nextQuiz }
  }
)
